@media only screen and (min-width: 467px) {
    .search-btn {
        position: absolute;
        bottom: 2px;
    }
}

.table {
    table-layout: fixed;
}

.table>thead>tr>th,
.table>tbody>tr>td {
    text-align: center;
}

.btn-close {

    font-weight: bold;
}