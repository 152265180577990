@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~font-awesome/css/font-awesome.css";
// @import '~ngx-toastr/toastr.css';

@import './assets/styles/styles-2.scss';

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    border: 1px solid red;
}

input.ng-valid.ng-touched,
select.ng-valid.ng-touched,
textarea.ng-valid.ng-touched {
    border: 1px solid green;
}

.required:after {
    color: red;
    content: " *";
}

.hidden {
    display: none;
}

.tr {
    cursor: pointer;
}

.table>thead>tr>th,
.table>tbody>tr>td {
    vertical-align: top;
}

.no-wrap-text {
    white-space: nowrap;
}

.fa-copy:hover {
    color: red;
}

.clip-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    overflow: hidden
}

.link-blue {
    color: #007bff !important;
    text-decoration: underline !important;
}

@media(min-width: 468px) {
    .search-btn {
        margin-top: 1.8rem;
    }
}

.pd-0 {
    padding: 0;
}

.pd-05 {
    padding: 0.5rem !important;
}

.pd-1 {
    padding: 1rem !important;
}

.bold {
    font-weight: bold;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.text-capital {
    text-transform: capitalize;
}

.pdr-05 {
    padding-right: 0.5rem;
}

.clickable {
    cursor: pointer;
}

.clip-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px;
    display: block;
    overflow: hidden
}

.link-blue {
    color: #007bff !important;
    text-decoration: underline !important;
}

.fw {
    width: 100% !important;
}

.no-padding,
.pd-0 {
    padding: 0 !important;
}