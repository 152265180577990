.dropdown-menu {
    display: block;
}

#sidebar-toggle-btn {
    display: none;
}

@media(max-width: 750px) {
    #sidebar-toggle-btn {
        display: block;
    }

}

// .logo {
//     max-width: 150px;
// }
.dropdown {
    display: block !important;
}