.modal-close-btn {
    position: absolute;
    top: 0;
    right: 0
}

.modal-body {
    padding: .5rem !important;
}

.twofa-card-body {
    max-height: 350px;
    overflow-y: auto;
}