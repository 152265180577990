// .table>thead>tr>th {
//     text-align: left;
// }

.contract-address {
    white-space: normal;
    word-wrap: break-word;

    // whiteSpace: 'normal'; wordWrap: 'break-word'
}

label {
    margin-bottom: 0 !important;
}